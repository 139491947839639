<template>
  <div class="ground-content">
    <div
      class="ground-table border-radius-6 box-shadow-light-grey padding-20"
      style="padding-top: 16px"
    >
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        :loading="loading"
        :showPagination="true"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        height="650px"
        :isUseCheckbox="true"
        @selection-change="handleSelectChange"
        @pageChange="handlePageChange"
      >
        <template #filter>
          <el-button class="margin-right-ten" @click="handlePageChange(1)"
            >刷新数据</el-button
          >
          <el-button
            type="primary"
            class="margin-right-ten"
            @click="handleUpload"
            >批量上传</el-button
          >
          <el-select
            v-model="params.status"
            filterable
            style="width: 180px"
            class="margin-right-ten"
            reserve-keyword
            clearable
            placeholder="选择状态"
          >
            <el-option label="未上传" :value="1"> </el-option>
            <el-option label="已上传" :value="2"> </el-option>
          </el-select>
          <el-input
            v-if="!isDrawer"
            clearable
            style="width: 180px"
            class="margin-right-ten"
            placeholder="公众号标识"
            v-model="params.appflag"
          ></el-input>
          <el-input
            clearable
            class="margin-right-ten"
            style="width: 180px"
            placeholder="创意id"
            v-model="params.creativeId"
          ></el-input>
          <el-input
            clearable
            class="margin-right-ten"
            placeholder="	计划id"
            style="width: 180px"
            v-model="params.aid"
          ></el-input>
          <el-button
            type="primary"
            class="margin-right-ten"
            @click="handlePageChange"
            >搜索</el-button
          >
        </template>
        <template #status="{ row }">
          {{ row.status === 1 ? "未上传" : "已上传" }}
        </template>
        <template #operation="{ row }">
          <el-button
            type="primary"
            size="small"
            class="button-small"
            @click="handleUploadSingle(row.id)"
            >上传</el-button
          >
        </template>
      </dy-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getQuickAppCallBack, uploadQuickAppCallBack } from "@/api/novelPut";
export default {
  props: {
    isDrawer: {
      type: Boolean,
      default: false,
    },
    flag: String,
  },
  data() {
    return {
      loading: false,
      rowList: [
        {
          label: "列表id",
          prop: "id",
        },
        {
          label: "openid",
          prop: "openid",
          popover: true,
        },
        {
          label: "计划id",
          prop: "aid",
        },
        {
          label: "公众号标识",
          prop: "appflag",
        },
        {
          label: "创意id",
          prop: "creativeId",
        },
        {
          label: "阅文用户id",
          prop: "guid",
        },
        {
          label: "上传状态",
          prop: "status",
          slot: true,
        },
        {
          label: "订单金额",
          prop: "orderAmt",
        },
        {
          label: "创建时间",
          prop: "createdTime",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      dataList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      deleteIds: [],
      params: {},
      deleteIds: [],
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  methods: {
    handleUploadSingle(id) {
      uploadQuickAppCallBack(id).then((res) => {
        this.$message.success("上传成功");
        this.handlePageChange();
      });
    },
    handleUpload() {
      let tmpids = this.deleteIds.map((item) => item.id) || [];
      uploadQuickAppCallBack(tmpids.join(",")).then((res) => {
        this.$message.success("上传成功");
        this.handlePageChange();
      });
    },
    handleSelectChange(item) {
      this.deleteIds = item;
    },
    handlePageChange(page) {
      this.loading = true;
      this.page = typeof page === "number" ? page : this.page;
      getQuickAppCallBack({
        page: this.page,
        pageSize: this.pageSize,
        appflag: this.params.appflag,
        status: this.params.status,
        creativeId: this.params.creativeId,
        aid: this.params.aid,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.isDrawer) {
      this.$set(this.params, "appflag", this.flag);
    }
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
.ground-content {
  height: 100%;
  .operation {
    background: #fff;
    box-sizing: border-box;
    // padding:  0 20px;
  }
  .ground-table {
    background: #fff;
  }
}
</style>