<template>
  <div class="ground-content">
    <div
      class="ground-table border-radius-6 box-shadow-light-grey padding-20"
      style="padding-top: 16px"
    >
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        :loading="loading"
        :isUseCheckbox="true"
        :showPagination="true"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        height="650px"
        @selection-change="handleSelectChange"
        @pageChange="handlePageChange"
      >
        <!-- test -->
        <template #filter>
          <el-button @click="handlePageChange(1)">刷新数据</el-button>
          <el-button
            @click="handleAddQuck"
            type="primary"
            class="margin-right-ten"
            >新增</el-button
          >
          <el-select
            v-model="accountChannelId"
            filterable
            style="width: 180px"
            reserve-keyword
            placeholder="选择公众号"
            clearable
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="(item, index) in officialList"
              :label="item.channelName"
              :value="item.id"
              :key="index"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
          <el-button
            @click="handleDeleteQuck"
            type="danger"
            class="margin-left-ten"
            >批量删除</el-button
          >
        </template>
        <template #startDate="{ row }">
          <div>
            <p v-for="(item, index) in row.timeList" :key="index">
              {{ item.startDate + " - " + item.endDate }}
            </p>
          </div>
        </template>
        <template #callbackRate="{ row }">
          <span>{{ row.callbackRate + "%" }}</span>
        </template>
        <template #minAmt="{ row }">
          <div>
            <span>{{
              (row.minAmt === undefined ? "" : row.minAmt) +
              " - " +
              (row.maxAmt === undefined ? "" : row.maxAmt)
            }}</span>
          </div>
        </template>
        <template #operation="{ row }">
          <el-button
            type="primary"
            size="small"
            class="button-small"
            @click="handleEditQuck(row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="button-small"
            @click="handleGetDetail(row)"
            >详情</el-button
          >
        </template>
      </dy-table>
      <!-- 新增编辑 -->
      <el-dialog
        center
        :title="isEdit ? '修改设置' : '新增设置'"
        :visible.sync="showEdit"
        width="500px"
      >
        <div class="form-container">
          <el-form ref="form" label-width="100px">
            <el-form-item class="necessary" label="公众号">
              <el-select
                v-model="formParams.accountChannelId"
                filterable
                style="width: 300px"
                reserve-keyword
                placeholder="选择公众号"
              >
                <el-option
                  v-for="(item, index) in officialList"
                  :label="item.channelName"
                  :value="item.id"
                  :key="index"
                >
                  <span style="float: left">{{ item.channelName }}</span>
                  <span style="float: right; margin-left: 15px; font-size: 13px"
                    >{{ item.platformStr }} - {{ item.uname }}</span
                  >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="necessary" label="公众号标识">
              <el-input
                style="width: 300px"
                v-model="formParams.appflag"
              ></el-input>
            </el-form-item>
            <el-form-item label="订单金额">
              <div class="time-list">
                <c-input
                  placeholder="最小订单金额"
                  onlyNum
                  style="width: 140px"
                  v-model="formParams.minAmt"
                ></c-input>
                <span style="margin: 0 5px">-</span>
                <c-input
                  placeholder="最大订单金额"
                  onlyNum
                  style="width: 140px"
                  v-model="formParams.maxAmt"
                ></c-input>
              </div>
            </el-form-item>
            <el-form-item class="necessary" label="回传时间">
              <div class="time-list">
                <div
                  v-for="(item, index) in timeList"
                  :key="index"
                  class="time-item"
                >
                  <el-time-picker
                    :picker-options="{
                      selectableRange: '00:00:01 - 23:59:59',
                    }"
                    value-format="HH:mm:ss"
                    style="width: 140px"
                    placeholder="起始时间"
                    v-model="item.startDate"
                  >
                  </el-time-picker>
                  <span style="margin-left: 5px">-</span>
                  <el-time-picker
                    :picker-options="{
                      selectableRange: '00:00:01 - 23:59:59',
                    }"
                    value-format="HH:mm:ss"
                    placeholder="结束时间"
                    style="width: 140px; margin-left: 5px"
                    v-model="item.endDate"
                  >
                  </el-time-picker>

                  <el-button
                    v-if="index === timeList.length - 1"
                    type="primary"
                    class="add-time"
                    style="padding: 5px; margin-left: 4px"
                    icon="el-icon-plus"
                    @click="handleAddTime"
                    circle
                  ></el-button>
                  <el-button
                    v-if="index !== 0"
                    type="danger"
                    class="add-time"
                    style="padding: 5px; margin-left: 4px"
                    icon="el-icon-close"
                    @click="handleDeleteTime(index)"
                    circle
                  ></el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="necessary" label="回传比例">
              <c-input
                placeholder="回传率小于等于100"
                v-model="formParams.callbackRate"
                style="width: 300px"
                :onlyNum="true"
              >
              </c-input>
              <!-- <el-input
                style="width: 300px"
                v-model="formParams.callbackRate"
              ></el-input> -->
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer">
          <el-button @click="showEdit = false">取消</el-button>
          <el-button type="primary" @click="handleAddSubmit">确定</el-button>
        </span>
      </el-dialog>
      <!-- 详情 -->
      <el-drawer
        :title="'详情：' + currentAppflag"
        :visible.sync="showDetail"
        size="90%"
        :destroy-on-close="true"
      >
        <callbackData
          v-if="showDetail"
          :isDrawer="true"
          :flag="currentAppflag"
        />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import callbackData from "./callBackData.vue";
import {
  getQuickApp,
  addQuickApp,
  updateQuickApp,
  deleteQuickApp,
} from "@/api/novelPut";
export default {
  components: {
    callbackData,
  },
  data() {
    return {
      loading: false,
      rowList: [
        {
          label: "ID",
          prop: "id",
        },
        {
          label: "公众号",
          prop: "channelName",
        },
        {
          label: "公众号标识",
          prop: "appflag",
        },
        {
          label: "回传率",
          prop: "callbackRate",
          slot: true,
        },
        {
          label: "订单金额",
          prop: "minAmt",
          slot: true,
        },
        {
          label: "回传时间",
          prop: "startDate",
          slot: true,
        },
        {
          label: "创建时间",
          prop: "createdTime",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      dataList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      accountChannelId: null,
      showEdit: false,
      isEdit: false,
      formParams: {},
      editId: null,
      timeRange: ["09:00:00", "18:00:00"],
      deleteIds: [],
      timeList: [
        {
          startDate: "09:00:00",
          endDate: "18:00:00",
        },
      ],
      // 详情相关
      showDetail: false,
      currentAppflag: null,
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  methods: {
    handleGetDetail(row) {
      this.currentAppflag = row.appflag;
      this.showDetail = true;
    },
    handleDeleteTime(index) {
      this.timeList.splice(index, 1);
    },
    handleAddTime() {
      this.timeList.push({
        startDate: "",
        endDate: "",
      });
    },
    handleSelectChange(item) {
      this.deleteIds = item;
    },
    handlePageChange(page) {
      this.loading = true;
      this.page = typeof page === "number" ? page : this.page;
      getQuickApp({
        page: this.page,
        pageSize: this.pageSize,
        accountChannelId: this.accountChannelId,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleAddQuck(type) {
      this.isEdit = false;
      this.showEdit = true;
      this.formParams = {};
      this.timeList = [
        {
          startDate: "09:00:00",
          endDate: "18:00:00",
        },
      ];
    },
    handleDeleteQuck() {
      this.$confirm("确定要批量删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let tmpids = this.deleteIds.map((item) => item.id) || [];
        deleteQuickApp(tmpids.join(",")).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    handleEditQuck(row) {
      this.timeRange = [row.startDate, row.endDate];
      this.editId = row.id;
      this.formParams = {
        accountChannelId: row.accountChannelId,
        callbackRate: row.callbackRate,
        appflag: row.appflag,
        minAmt: row.minAmt,
        maxAmt: row.maxAmt,
      };
      if (row.timeList) {
        this.timeList = row.timeList.map((item) => ({
          startDate: item.startDate,
          endDate: item.endDate,
        }));
      } else {
        this.timeList = [
          {
            startDate: "09:00:00",
            endDate: "18:00:00",
          },
        ];
      }

      this.showEdit = true;
      this.isEdit = true;
    },
    handleAddSubmit(type) {
      if (!this.formParams.accountChannelId) {
        return this.$message.error("请选择公众号");
      }
      let timeFlag = this.timeList.some((item) => {
        if (!item.startDate || !item.startDate) {
          return true;
        } else {
          return false;
        }
      });
      if (timeFlag) {
        return this.$message.error("请完善回传时间");
      }
      if (!this.formParams.callbackRate) {
        return this.$message.error("请输入回传比列");
      }

      if (this.formParams.callbackRate > 100) {
        return this.$message.error("回传比列需小于100");
      }
      if (!this.formParams.appflag) {
        return this.$message.error("请输入公众号标识");
      }
      if (this.isEdit) {
        updateQuickApp(this.editId, {
          timeList: this.timeList,
          ...this.formParams,
        })
          .then((res) => {
            this.$message.success("修改成功");
            this.showEdit = false;
            this.isEdit = false;
            this.handlePageChange();
          })
          .finally(() => {});
      } else {
        addQuickApp({
          timeList: this.timeList,
          ...this.formParams,
        })
          .then((res) => {
            this.$message.success("新增成功");
            this.showEdit = false;
            this.isEdit = false;
            this.handlePageChange();
          })
          .finally(() => {});
      }
    },
  },
  created() {
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
.ground-content {
  height: 100%;
  .operation {
    background: #fff;
    box-sizing: border-box;
    // padding:  0 20px;
  }
  .ground-table {
    background: #fff;
  }
}
.time-list {
  .time-item ~ .time-item {
    margin-top: 4px;
  }
  .add-time {
    padding: 4px !important;
  }
}
</style>